import type { HttpErrorResponse, HttpEvent, HttpInterceptorFn } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { StorageService } from '../auth/storage.service';
import { Router } from '@angular/router';
import { DataService } from '../../shared/services/data.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  let storage = inject(StorageService);
  let dataService = inject(DataService);
  let _Router = inject(Router);
  return next(req).pipe(map(
    (event: HttpEvent<any>) => {
      return event;
    }
  ),
    catchError(
      (error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            storage.removeToken();
            dataService.showMsg('fail', 'You Don\'t Have Permission To Access This Page. Please Login Again.');
            _Router.navigate(['auth/login']);
            break;

          case 403:
            // handle not Authorized case here
            break;

          case 404:
            // handle not found case here
            break;

          case 500:
            // handle server error case here
            break;

          default:
            // handle other cases here
            dataService.showMsg('fail', error.error.Error[0]);
            break;
        }

        dataService.showLoading(false);
        return throwError(() => error);
      })
  );
};
